<template>
  <tr class="inspection-section-row">
    <td v-show="hasHints">
      <select :class="['hint-list', {'hints-unavailable': hintsDisabled}]" ref="hintContainer" size="1" v-model="selectedHint" :title="hintTitle" :disabled="hintsDisabled">
        <option class="hint-text" v-for="hint in rowHints" :key="hint.Ref" :value="hint.Ref">
          <span>
            {{ hintSolutionReference(hint) }}{{ hintSolutionDescription(hint) }}{{ hintSolutionRating(hint) }}
          </span>
        </option>
      </select>
    </td>
    <td v-for="column in columns" class="inspection-table-cell" v-show="!(chosenField(column).type == 'guidance')">
      <component
        :is="componentFor(chosenField(column))"
        :specification="chosenField(column)"
        :data="data[chosenField(column).reference]"
        :notifyFieldData="notifyFieldData[chosenField(column).reference]"
        @registerFieldwatchers="val => registerFieldwatchers(val)"
        @registerLinks="val => registerLinks(val)"
        @update:data="val => fieldUpdate(chosenField(column), val)"
        @clear:data="() => fieldRemoval(chosenField(column))"
        :read-only="readOnly"
        :isRow=true
        :isNewForm="newForm"
        :under-rollup="getRollup(chosenField(column).reference)"
        :group-visible="getGroupVisible(chosenField(column).reference)"
        :group-state="getGroupState(chosenField(column).reference)"
        :control-event="controlEventName(chosenField(column).reference)"
        :control-state="getControlState(chosenField(column).reference)"
        :show-validation="showValidation"
        :validate-now="validateNow"
        :parentReference="instanceReference"
        @update:errors="newErrors => updateValidityState(newErrors)"
        @notifyFieldEvent="val => onNotifyFieldEvent(val)"
        :key="chosenField(column).reference">
      </component>
    </td>
  </tr>
</template>

<script>
  import DateField from './fields/date.vue'
  import DecimalField from './fields/decimal.vue'
  import FeatureField from './fields/feature.vue'
  import GuidanceField from './fields/guidance.vue'
  import IntegerField from './fields/integer.vue'
  import LocationField from './fields/location.vue'
  import SelectionField from './fields/selection.vue'
  import TextField from './fields/text.vue'
  import TimeField from './fields/time.vue'
  import PhotoField from './fields/photo.vue'

  export default {
    components: {
      DateField,
      DecimalField,
      FeatureField,
      GuidanceField,
      IntegerField,
      LocationField,
      SelectionField,
      TextField,
      TimeField,
      PhotoField
    },
    props: {
      fields: Array,
      data: Object,
      readOnly: Boolean,
      newForm: Boolean,
      underRollups: Object,
      fieldsToUse: Array,
      columns: Array,
      defaultRollup: Object,
      controlledFields: Array,
      showValidation: Boolean,
      validateNow: String,
      instanceReference: String,
      notifyFieldEvent: Object,
    },
    inject: ['sourceLists','hints','filterOn','targetFields','includeOverRating'],
    data: function () {
      let initialStates = this.evaluateRules()
      let initialNotify = _.reduce(this.fields, (o, f) => {
        o[f.reference] = {};
        return o;
        }, {});
      return {
        dataChanged: false,
        controlledFieldStates: initialStates,
        fieldValidity: [],
        currentValidity: 'invalid',
        selectedHint: null,
        showChildRow: false,
        registeredLinks: [],
        notifyFieldData: initialNotify, 
      }
    },
    watch: {
      selectedHint: function(ref) {
        this.addToForm(ref);
      },
      notifyFieldEvent: function(val) {
        this.onNotifyFieldEvent(val);
      },
    },
    computed: {
      hasHints: function () {
        return !_.isEmpty(this.hints());
      },
      rowHasHints: function () {
        return !_.isEmpty(this.rowHints);
      },
      hintsDisabled: function () {
        return !this.rowHasHints || this.isReadOnly;
      },
      hintTitle: function () {
        return this.rowHasHints ? 'Matching options: ' + this.rowHints.length : 'No matching hints';
      },
      rowHints: function () {
        let vm = this;
        return _.filter(vm.hints(), function (hintValue) {
          return vm.hintPredicate(hintValue)
        });
      }
    },
    methods: {
      registerFieldwatchers: function(reg) {
        let vm = this;
        _.forEach(reg.fields ?? [], field => {
          let w = [...vm.watchedFields[field] ?? [], reg.watcher];
          let watchers = _.uniq(w);
          vm.watchedFields[field] = watchers;
        });
      },
      registerLinks: function(reg) {
        let newReg = _.map(reg.links, (l) => { return {ownerReference: reg.owner, targetReference: l}; })
        this.registeredLinks = this.registeredLinks.concat(newReg);
      },
      onFieldChanged(updatedFieldReference, data) {
        let watchers = this.watchedFields[updatedFieldReference];
        if (watchers) {
          let ev = {
            type: "watchedFieldChanged",
            fields: data,
          };
          _.forEach(watchers, (watcher) => {
            this.notifyFieldData[watcher] = ev;
          });
        }
      },
      onNotifyFieldEvent: function(val) {
        if (!val.destination) {
          _.forEach(this.registeredLinks, (l) => {
            if (l.targetReference == val.source) {
              val.destination = l.ownerReference;
              this.notifyFieldData[l.ownerReference] = val;
            }
          });
        }
        if (!!val.destination) {
          // we work with relative paths only
          // we're making the assumption that there are no deep paths
          // TODO: adapt to support nested features
          // TODO: adapt to support parent paths
          // TODO: adapt to support absolute paths (eg if path starts with a '/')
          let parts = val.destination.split(':');
          if (parts.length == 2) {
            val.destination = parts[1];
            this.notifyFieldData[parts[0]] = val;
          } else {
            this.notifyFieldData[val.destination] = val;
          }
        }
      },
      hintSolutionReference: function (hint) {
        return (_.isNil(hint['solutionReference'])) ? "" : hint['solutionReference'];
      },
      hintSolutionDescription: function (hint) {
        return (_.isNil(hint['solutionDescription'])) ? "" : ", " + hint['solutionDescription'];
      },
      hintSolutionRating: function (hint) {
        return (_.isNil(hint['fireRating'])) ? "" : ", " + hint['fireRating'];
      },
      addToForm: function (hintRef) {
        // add current values into current data then hide hint Container
        let vm = this;
        
        let thisHint = _.cloneDeep(_.find(this.rowHints, function (hint) { return hint.Ref == hintRef }));
        let exclusions = _.keys(vm.filterOn);
        exclusions.push('Ref');
        
        let hintFields = _.pickBy(thisHint, function (value, key) { return !_.includes(exclusions, key) });
        
        let localData = _.cloneDeep(vm.data);
        let sendData = false;

        _.forEach(hintFields, function (value, key) {
          if (!_.isNil(vm.findField(key))) {
            let dataToSend = {};
            let fieldData = vm.data[key];
            dataToSend = _.isNil(fieldData) ? {} : _.cloneDeep(fieldData);

            // For now only update text fields
            switch (vm.fieldType(key)) {
              case 'text':
                dataToSend['value'] = thisHint[key];
                dataToSend['type'] = 'text';            
                //vm.fieldUpdate(vm.findField(key),dataToSend);
                localData[key] = dataToSend;
                sendData = true;
                break;
              case 'selection':
                let localValue = vm.matchOptionValue(vm.findField(key),thisHint[key]);
                if (!_.isEmpty(localValue)) {
                  dataToSend['selections'] = localValue;
                  dataToSend['type'] = 'selection';            
                  //vm.fieldUpdate(vm.findField(key),dataToSend);
                  localData[key] = dataToSend;
                  sendData = true;                                  
                }
            }
          }
        });
        if (sendData) { vm.$emit('update:data', localData) };
      },
      hintText: function (hint) {
        let vm = this;
        return _.join(_.map(hint, function (field) { return _.find(vm.targetFields, field.Ref)}, ", "));
      },
      hintPredicate: function (hintValue) {
        // find the field
        let vm = this;
        let result = false;
        result =  _.every(_.keys(vm.filterOn), function (filterReference) {
          if (_.isEmpty(vm.data[filterReference])) {
            return true;
          } else {
            switch (vm.data[filterReference].type) {
              case 'text':
                return hintValue[filterReference] == vm.data[filterReference]['value'];
              break;
              case 'selection':
                if (_.isEmpty(vm.data[filterReference]["selections"])) {
                  return true;
                } else {
                  if (vm.filterOn[filterReference] == 'Fire Rating') {
                    return !_.isNil(_.find(vm.data[filterReference]['selections'], function (selection) {
                      return vm.compareFireRating(hintValue[filterReference],selection.value,vm.includeOverRating());
                      }));
                  } else {
                    return !_.isNil(_.find(vm.data[filterReference]['selections'], function (selection) {
                      return (hintValue[filterReference] == selection.value);
                      }));
                  }
                }
            }
          }
        });
        return result;
      },
      compareFireRating: function (solutionRating, substrateRating, includeOverRated) {
        // Do they match exactly? (smoke, acoustic or direct match)
        if (solutionRating == substrateRating ) { 
          return true ;
        } else {
          // Are they numeric?
          let arr1 = solutionRating.split('/');
          let arr2 = substrateRating.split('/');

          if ((arr1.length != arr2.length) || (arr1.length != 3) || (arr2.length != 3)) { 
            // Completely different
            return false ; 
          } else {
            // check specific integrity and insulation
            let int1 = (arr1[1] == '-') ? 0 : parseInt(arr1[1]);
            let ins1 = (arr1[2] == '-') ? 0 : parseInt(arr1[2]);
            let int2 = (arr2[1] == '-') ? 0 : parseInt(arr2[1]);
            let ins2 = (arr2[2] == '-') ? 0 : parseInt(arr2[2]);

            // Meets criteria
            if ((int1 == int2) && (ins1 == ins2)) { 
              return true ;
            } else {
              if (includeOverRated) {
                // look for overrated
                if (((int1 > int2) && (ins1 >= ins2)) || ((int1 >= int2) && (ins1 > ins2)) || ((int1 > int2) && (ins1 > ins2))) {
                  return true ;
                } else {
                  return false ;
                }                
              } else {
                return false ;
              }
            }
          }
        }
      },
      updateValidityState: function (fieldError) {
        let fieldName = _.keys(fieldError)[0];
        let fieldValue = fieldError[fieldName];
        let fieldIndex = -1;
        let originalValidity = this.currentValidity;
        let vm = this;
        if (fieldValue == 'valid' && _.includes(vm.fieldValidity, fieldName)) {
          // field was in array, remove it
          fieldIndex = _.findIndex(vm.fieldValidity, function (entry) {
            return entry == fieldName;
          });
          if (fieldIndex > -1) {
            // found it - remove it
            vm.fieldValidity.splice(fieldIndex, 1);
          }
        } ;
        if (fieldValue == 'invalid' && !_.includes(vm.fieldValidity, fieldName)) {
          // add it in
          vm.fieldValidity.push(fieldName);
        } ;

        //if (originalValidity != vm.setCurrentValidity()) {
          let dataToSend = {};
          dataToSend[vm.instanceReference] = vm.setCurrentValidity(); //vm.currentValidity;
          this.$emit('update:errors', dataToSend);
        //}
      },
      setCurrentValidity: function () {
        this.currentValidity = (this.fieldValidity.length == 0) ? 'valid' : 'invalid';
        return this.currentValidity;
      },
      getControlState: function (fieldRef) {
        return this.controlledFieldStates[fieldRef]
      },
      controlEventName: function (fieldRef) {
        let vm = this;
        let result = '';
        if (_.includes(vm.controlledFields, fieldRef)) {
          result = (vm.instanceReference + '-' + 'showHideResults');
        }
        return result;
      },
      getRollup: function (fieldRef) {
        return !!this.underRollups[fieldRef] ? this.underRollups[fieldRef] : '';
      },
      getGroupVisible: function(fieldRef) {
        let thisVisible = true;
        let vm = this;
        if (!!this.underRollups[fieldRef]) {
          // check the feild is not the same as the rollup - can't control self!
          if (fieldRef != _.last(_.split(vm.getRollup(fieldRef),'-'))) {
            thisVisible = !!!this.defaultRollup[this.getRollup(fieldRef)];          
          }
        } 
        return thisVisible;
      },
      getGroupState: function(fieldRef) {
        let thisState = '';
        let vm = this;
        if (!!this.underRollups[fieldRef]) {
          // check the feild is not the same as the rollup - can't control self!
          let groupField = _.last(_.split(vm.getRollup(fieldRef),'-'))
          if (fieldRef != groupField) {
            thisState = vm.getControlState(groupField)
            if (!thisState) {
              // group not found in controlled fields list -> use the schema default
              let groupFieldSchema = vm.fields.find(f => f.reference == groupField);
              if (groupFieldSchema) {
                thisState = groupFieldSchema.hidden ? "hide" : "show";
              }
            }
          }
        } 
        return thisState;
      },
      componentFor: function (field) {
        return field.type + "-field";
      },
      chosenField: function (column) {
        return _.find(this.fieldsToUse,{'reference': column});
      },
      findField: function (reference) {
        return _.find(this.fields,{'reference': reference});
      },
      fieldType: function (reference) {
        return this.findField(reference).type;
      },
      fieldUpdate: function (field, value) {
        let reference = field.reference
        let data = _.cloneDeep(this.data)
        data[reference] = value

        this.$emit('update:data', data)
        this.onFieldChanged(reference, data)
      },
      fieldRemoval: function (field) {
        let reference = field.reference
        let data = _.cloneDeep(this.data)
        delete data[reference]

        this.$emit('update:data', data)
        this.onFieldChanged(reference, data)
      },
      hasDefault: function (field) {
        return !_.isEmpty(field.default_selections)
            || ((field.type === 'date' || field.type === 'time') && (!_.has(field, 'set_on_create') || field.set_on_create));
      },
      isMulti: function (field) {
        return field.multiple;
      },
      originalOptions: function (field) {
        return this.fullOptionsToUse(field);
        // return _.map(field.options, function (option) {
        //   return {key: option[0], value: option[1]}
        // })
      },
      fullOptionsToUse: function (field) {
        // Options, lists and any selected key value pairs
        let vm = this;
        let fullOptions = [];
        let selectedData = vm.data[field.reference];
        let keyedData = _.map(selectedData, _.property('key'));
        if (!!field['options'] && !_.isEmpty(field['options'])) {
          // we have options - use them
          fullOptions = _.map(field.options, function (option) {
            return {key: option[0], value: option[1]}
          });
        } else if (!!field['source'] && !_.isEmpty(vm.sourceLists[field['source']])) {
          let optionsToUse = _.cloneDeep(vm.sourceLists[field['source']].items);

          fullOptions = _.map(_.filter(optionsToUse, function (listItem) { 
            return _.includes(keyedData, listItem.key) ? true : listItem.use; 
          }), function(listItem) { return {key: listItem.key, value: listItem.value } });
        }
        // Check actual data is in the full options (whether in list or not)
        keyedData = _.map(fullOptions, _.property('key'));
        _.forEach(selectedData, function(dataItem) { if (!_.includes(keyedData, dataItem.key)) {
            fullOptions.unshift(dataItem);
          }
        });
        return fullOptions;
      },
      matchOptionValue: function (field, value) {
        let vm = this;
        return _.filter(vm.originalOptions(field), { 'value': value });        
      },
      setDefaults: function(field) {
        if (this.hasDefault(field)) {
          this.dataChanged = true;
          let reference = field.reference
          let existing = this.data[reference];
          if (!existing) {
            let now = new Date();
            switch(field.type) {
              case 'date':
                this.data[reference] = {
                  type: 'date',
                  year: now.getFullYear(),
                  month: now.getMonth() + 1,
                  day: now.getDate()
                };
                break;
              case 'time':
                this.data[reference] = {
                  type: 'time',
                  hour: now.getHours(),
                  minute: now.getMinutes(),
                  second: 0
                };
                break;
              case 'selection':
                this.data[reference] = { 
                  type: 'selection',
                  selections: this.getSelection(field)
                };
            }
          }
        }
      },
      getSelection: function (field) {
        // if new and no data - add defaults
        let newSelections = this.isMulti(field) ? field.default_selections : field.default_selections[0];
        let selectedKeys = _.castArray(newSelections);
        let selectedOptions = _.filter(this.originalOptions(field), function (o) {
          return _.includes(selectedKeys, o.key);
        });
        return selectedOptions;
      },
      evaluateRule: function (thisRule, selections) {
        let test = _.toUpper(thisRule.test);
        let searchVals = thisRule.keys;
        let fields = thisRule['field-references'];
        let emptySelection = (_.isEmpty(selections)) || (_.filter(selections, _.isEmpty).length >= 1);
        let matched = false;
        if (test == 'OR') {
          if (_.isEmpty(searchVals) && emptySelection) {
            matched = true;
          } else {
            _.forEach(searchVals, function(thisValue) {
              if (thisValue == "") {
                if (emptySelection) {
                  matched = true
                }
              } else { 
                if (_.includes(selections, thisValue)) {
                  // If any matched
                  matched = true
                }
              }
            })            
          }

        } 
        if (test == 'AND') {
          // Treat as AND - all have to match
          let misMatch = ''; // first time
          _.forEach(searchVals, function(thisValue) {
            if (!_.includes(selections, thisValue)) {
              misMatch = thisValue;
            }
          })
          matched = !!!misMatch;
        }
        return matched;
      },
      evaluateRules: function () {
        // go through each rule, evaluate it. 
        // IF a rule matches addfields with behaviour to match list, 
        // else add fields to mistmath list
        let matched = {};
        let mismatched = {};
        let vm = this;
        _.forEach(this.fields, function(thisField) {
          if (thisField.type == 'selection') {
            // check for rules
            if (!!thisField['field-control']) {
              // we have fields to control
              let rules = thisField['field-control'];
              _.forEach(rules, function(thisRule){
                let behaviour = _.toLower(thisRule.behaviour);
                let selections = !vm.data[thisField.reference] ? [] : vm.data[thisField.reference]['selections'];
                if ((vm.newForm && !vm.isReadOnly) && _.isEmpty(selections) && vm.hasDefault(thisField)) { selections = vm.getSelection(thisField) };
                let selectionKeys = [];
                _.forEach(selections, entry => selectionKeys.push(entry.key));
                if (vm.evaluateRule(thisRule, selectionKeys)) {
                  // add matched fields
                  _.forEach(thisRule['field-references'], function(thisFieldName) {
                    if (!!!matched[thisFieldName]) {
                      // if fieldname alrady matched, do not alter entry
                      matched[thisFieldName] = behaviour;
                    }
                  });
                } else {
                  _.forEach(thisRule['field-references'], function(thisFieldName) {
                    mismatched[thisFieldName] = (behaviour == 'show' ? 'hide' : 'show');
                  });
                };
              });
              // merge data into one Hash
              _.forEach(mismatched, function(entry, key) { if (!!!matched[key]) {matched[key] = entry}})
            }
          }
        })
        return matched;
      }
    },
    created: function() {
      // iterate thorugh data and add defaults if there are any
      if (this.newForm && !this.isReadOnly) {
        _.forEach(this.fields, field => this.setDefaults(field));
        if (this.dataChanged) { 
          let data = _.cloneDeep(this.data);
          this.$emit('update:data', data) ;
        };
      }
    }
  }
</script>

<style scoped lang="scss">
  .inspection-table-cell {
    padding: 0px;
  }
  .hint-list {
    width:20px;
    background-color: #0000FF;
    color: white;
    margin-bottom: 0;
    margin-right:4px;
    margin-left:2px;
    border-radius:10px;
    cursor: pointer;
  }
  .hints-unavailable {
    background-color: #b9b8b8;
    cursor: default;
  }
  .hint-text {
    cursor: pointer;
    background-color: white;
    color: black;
  }
  .hint-text:nth-of-type(odd) {
    background: #e0e0e0;
  }
</style>