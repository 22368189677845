<template>
  <div :class="['widget-box', 'membership-container', {dirty: isDirty}]">
    <div class="widget-title">
      <h5 class="membership-title">
        {{title}}
      </h5>
      <div class="title-badges">
        <span v-show="!membership.is_confirmed" class="label label-important admin-icon"><i class="icon-exclamation-sign icon-white"/> Unconfirmed</span>
      </div>
      <button v-if="canDestroy" :disabled="working" class="btn btn-small btn-danger pull-right remove-btn" v-on:click.prevent="destroy" title="Remove member">Remove</button>
    </div>
    <div v-show="showFailedRemove" class="alert alert-error">Could not remove '{{membership.email}}'.</div>
    <div class="membership-info-panel">
      <a v-if="!isMonitor" :href="mailTo" class="em">{{membership.email}}</a>
      <span v-else>{{name}} (<a :href="mailTo" class="em">{{membership.email}}</a>)</span>
      <span>Last Access: {{lastActiveLabel}}</span>
      <span v-show="membership.phone_number" class="em">Personal Phone: {{membership.phone_number}}</span>
      <div>
        <span v-show="isAdmin" class="label label-info admin-icon">Admin</span>
      </div>
    </div>
    <ul v-if="!isMonitor" class="nav nav-tabs">
      <li :class="['nav-item', {active: tab==0}]">
        <a v-on:click.prevent="tab=0" href="#">Details</a>
      </li>
      <li :class="['nav-item', {active: tab==1}]">
        <a v-on:click.prevent="tab=1" href="#">Signature
          <i v-show="hasSignature" class="material-icons" title="This member has a signature">check_circle</i>
        </a>
      </li>
    </ul>
    <div v-if="!isMonitor" class="membership-content">
      <div v-show="tab==0" class="membership-details">
        <div class="membership-details-item">
          <label :for="domId('membership-role')">Organisation role</label>
          <select v-model="role" :id="domId('membership-role')" :disabled="!canChangeRole" title='Level of user in Clarinspect'>
            <option :value="option.key" v-for="option in roles" :key="option.key">{{ option.value }}</option>
          </select>
        </div>
        <div class="membership-details-item">
          <label :for="domId('membership-account-manager')">Account manager?</label>
          <input type="checkbox" :id="domId('membership-account-manager')" :disabled="readOnly" v-model="accountManager" title='Can this user change account settings?'/>
        </div>
        <div class="membership-details-item">
          <label :for="domId('membership-qualifications')">Qualifications</label>
          <input type="text" :id="domId('membership-qualifications')" :disabled="readOnly" v-model="qualifications" title='e.g. BSc.Hons'/>
        </div>
        <div class="membership-details-item">
          <label :for="domId('membership-certificates')">Certificates</label>
          <input type="text" :id="domId('membership-certificates')" :disabled="readOnly" v-model="certificates" title='e.g. IP 402.1, HSE 43001'/>
        </div>
        <div class="membership-details-item">
          <label :for="domId('membership-organisation-role')">Job title</label>
          <input type="text" :id="domId('membership-organisation-role')" :disabled="readOnly" v-model="organisationRole" title='Their title, e.g. Licensed Assessor'/>
        </div>
        <div class="membership-details-item">
          <label :for="domId('membership-organisation-phone')">Phone number</label>
          <input type="text" :id="domId('membership-organisation-phone')" :disabled="readOnly" v-model="organisationPhone" title='DDI or preferred phone number'/>
        </div>
        <!-- <div class="membership-details-item">
          <label :for="domId('membership-reviewer-email')">Reviewer email</label>
          <input type="text" :id="domId('membership-reviewer-email')" :disabled="readOnly" v-model="reviewerEmail" title='Which other Clarinspect user should be considered a reviewer?'/>
        </div> -->
        <div class="save-container">
          <span v-show="showFailedUpdateMessage" class="alert alert-error alert-small">Could not save.</span>
          <span v-show="showSuccessfulUpdateMessage" class="alert alert-success alert-small">Saved.</span>
          <span v-show="isDirty" class="alert alert-warning alert-small">Unsaved.</span>
          <button v-if="!readOnly" :disabled="working" class="btn btn-small btn-primary" v-on:click.prevent="update">Save</button>
        </div>
      </div>
      <div v-show="tab==1" class="membership-signature">
        <div v-if="!readOnly" class="signature-controls">
          <div class="file-selector">
            <!-- TODO: disble the file-selector when working -->
            <file-selector
              mimeType="image/jpeg,image/png"
              typeLabel="signature jpeg or PNG"
              @file_selected="val => onFileSelected(val)"
            />
            <span v-show="showFailedSignatureMessage" class="alert alert-error alert-small">Could not save.</span>
            <span v-show="showSuccessfulSignatureMessage" class="alert alert-success alert-small">Saved.</span>
          </div>
        </div>
        <div class="signature-image-container" v-if="hasSignature">
          <img class="signature-image" :src="signatureUrl"/>
          <button v-if="!readOnly" :disabled="working" class="btn-remove-signature" v-on:click.prevent="removeSignature" title="Remove signature">
            <span class="material-icons">delete_outline</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import file_selector from './utils/combo_file_selector.vue'
import { MembershipAssetUploader } from "../lib/uploaders/membership_asset_uploader"

const imageMaxArea = 40000000;
const fileMaxSize = 20000000;

export default {
    components: {
      fileSelector: file_selector,
    },
  props: {
    membership: Object,
    readOnly: Boolean,
    canDestroy: Boolean,
    roles: Array,
    isCurrentMember: Boolean,
    lastActiveLabel: String,
  },
  data: function () {
    return {
      role: this.membership.role,
      accountManager: this.membership.account_manager,
      qualifications: this.membership.qualifications,
      certificates: this.membership.certificates,
      organisationRole: this.membership.organisation_role,
      organisationPhone: this.membership.organisation_phone,
      reviewerEmail: this.membership.reviewer_email,

      working: false,

      // tab 0
      showFailedUpdateMessage: false,
      showSuccessfulUpdateMessage: false,
      updateMessageTimeout: null,

      // tab 1
      showFailedSignatureMessage: false,
      showFailedRemoveSignatureMessage: false,
      showSuccessfulSignatureMessage: false,
      signatureMessageTimeout: null,

      // delete membership
      showFailedRemove: false,
      removeErrorTimeout: null,

      cacheBuster: '',

      tab: 0,
      signatureId: this.membership.signature_id,
    }
  },
  watch: {
    isDirty: function(isDirty) {
      if (isDirty) {
        this.resetUpdateMessages();
      }
    }
  },
  computed: {
    title: function () {
      return this.isMonitor ? 'Monitor' : this.name;
    },
    name: function () {
      return this.membership.full_name ?? "Registration not completed";
    },
    mailTo: function () {
      return 'mailto:' + this.membership.email;
    },
    canChangeRole: function () {
      return !this.isCurrentMember && !this.readOnly;
    },
    isAdmin: function () {
      return this.role == 'admin';
    },
    hasSignature: function () {
      return !!this.signatureId;
    },
    signatureUrl: function() {
      return '/o/' + this.membership.organisation_id + '/members/' + this.membership.id + '/assets/' + this.signatureId + '?t=' + this.cacheBuster;
    },
    isMonitor: function () {
      return this.membership.role == 'monitor';
    },
    isDirty: function () {
      return this.role != this.membership.role
      || this.accountManager != this.membership.account_manager
      || String(this.qualifications).trim() != String(this.membership.qualifications).trim()
      || String(this.certificates).trim() != String(this.membership.certificates).trim()
      || String(this.organisationRole).trim() != String(this.membership.organisation_role).trim()
      || String(this.organisationPhone).trim() != String(this.membership.organisation_phone).trim()
      || String(this.reviewerEmail).trim() != String(this.membership.reviewer_email).trim();
    },
  },
  methods: {
    domId: function(tag) {
      return tag + '_' + this.membership.id;
    },
    onFileSelected: function (file) {
      if (this.working || null == file || (file.type != 'image/jpeg' && file.type != 'image/png')) {
        return;
      }
      var _URL = window.URL || window.webkitURL;
      var img = new Image();
      let vm = this;

      img.onload = function() {
        if (this.width * this.height < imageMaxArea) {
          vm.onUpload(file);
        };
      };
      img.src = _URL.createObjectURL(file);
    },
    onUpload: async function (file) {
      let hasValidFileSize = file.size < fileMaxSize;
      let hasValidFileType = (file.type == 'image/jpeg' || file.type == 'image/png');
      if (this.working || null == file || !hasValidFileType || !hasValidFileSize) {
        // TODO: tell the users there's an error
        return;
      }
      let vm = this;
      reset();

      let uploader = MembershipAssetUploader();
      try {
        this.working = true;
        let newId = await uploader.uploadSignature(this.membership.organisation_id, this.membership.id, this.membership.signature_id, file);
        setTimeout(() => {
          vm.membership.signature_id = newId;
          vm.signatureId = newId;
          vm.cacheBuster = new Date().getTime();
          success();
        }, 2000);
      } catch (error) {
        fail();
      } finally {
        this.working = false;
      }

      function reset() {
        if (vm.signatureMessageTimeout) {
          clearTimeout(vm.signatureMessageTimeout);
          vm.signatureMessageTimeout = null;
        }
        vm.showSuccessfulSignatureMessage = false;
        vm.showFailedSignatureMessage = false;
        vm.showFailedRemoveSignatureMessage = false;
      };
      function success() {
        vm.showSuccessfulSignatureMessage = true;
        vm.signatureMessageTimeout = setTimeout(() => {
          vm.showSuccessfulSignatureMessage = false;
          vm.signatureMessageTimeout = null;
        }, 5000);
      };
      function fail() {
        vm.showFailedSignatureMessage = true;
        vm.signatureMessageTimeout = setTimeout(() => {
          vm.showFailedSignatureMessage = false;
          vm.signatureMessageTimeout = null;
        }, 5000);
      };
    },
    removeSignature: function() {
      if (this.working || !this.hasSignature) {
        return;
      }

      let vm = this;
      const url = '/o/' + this.membership.organisation_id + '/members/' + this.membership.id + '/assets/' + this.membership.signature_id + '/destroy_asset';
      reset();
      try {
        axios.delete(url).then(() => setTimeout(() => {
          delete vm.membership.signature_id;
          vm.signatureId = null;
          vm.cacheBuster = '';
        }, 2000));
      } catch {
        fail();
      }

      function reset() {
        if (vm.signatureMessageTimeout) {
          clearTimeout(vm.signatureMessageTimeout);
          vm.signatureMessageTimeout = null;
        }
        vm.showSuccessfulSignatureMessage = false;
        vm.showFailedSignatureMessage = false;
        vm.showFailedRemoveSignatureMessage = false;
      };
      function fail() {
        vm.showFailedRemoveSignatureMessage = true;
        vm.signatureMessageTimeout = setTimeout(() => {
          vm.showFailedRemoveSignatureMessage = false;
          vm.signatureMessageTimeout = null;
        }, 5000);
      };
    },
    update: async function() {
      let vm = this;
      this.resetUpdateMessages();
      try {
        vm.working = true;
        let url = window.location.pathname + '/' + vm.membership.id;
        let update = memberUpdate();
        await vm.$http.put(url, {membership: update});
        this.$emit('updated', update);
        success();
      } catch {
        fail();
      } finally {
        vm.working = false;
      }

      function memberUpdate() {
        return {
          role: vm.role,
          account_manager: vm.accountManager,
          qualifications: vm.qualifications,
          certificates: vm.certificates,
          organisation_role: vm.organisationRole,
          organisation_phone: vm.organisationPhone,
          reviewer_email: vm.reviewerEmail,
        };
      };
      function success() {
        vm.showSuccessfulUpdateMessage = true;
        vm.updateMessageTimeout = setTimeout(() => {
          vm.showSuccessfulUpdateMessage = false;
          vm.updateMessageTimeout = null;
        }, 5000);
      };
      function fail() {
        this.showFailedUpdateMessage = true;
        this.updateMessageTimeout = setTimeout(() => {
          vm.showFailedUpdateMessage = false;
          vm.updateMessageTimeout = null;
        }, 5000);
      };
    },
    resetUpdateMessages: function() {
      if (this.updateMessageTimeout) {
        clearTimeout(this.updateMessageTimeout);
        this.updateMessageTimeout = null;
      }
      this.showSuccessfulUpdateMessage = false;
      this.showFailedUpdateMessage = false;
    },
    destroy: async function() {
      let confirmed = confirm("Are you sure you want to remove '" + this.membership.email + "' from your organisation?");
      if (!confirmed) {
        return;
      }
      this.working = true;
      if (this.removeErrorTimeout) {
        clearTimeout(this.removeErrorTimeout);
        this.removeErrorTimeout = null;
      }
      let url = window.location.pathname + '/' + this.membership.id;
      try {
        await this.$http.delete(url);
        this.$emit('destroyed');
      } catch {
        this.showFailedRemove = true;
        let vm = this;
        this.removeErrorTimeout = setTimeout(() => {
          vm.showFailedRemove = false;
          vm.removeErrorTimeout = null;
        }, 5000);
      } finally {
        this.working = false;
      }
    },
  },
}
</script>

<style scoped>
.membership-container {
  display: flex;
  flex-direction: column;
  margin: 0;
  border-bottom: 1px solid #cdcdcd;
  width: var(--panel-width);
}
.widget-title {
  display: flex;
  justify-content: space-between;
}
.title-badges {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.membership-info-panel {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: #eaf6fb;
  line-height: 160%;
  width: 100%;
  box-sizing: border-box;
}
.membership-info-panel .em {
  font-weight: bold;
}
.admin-icon {
  float: none;
  margin: 0;
  box-shadow: 0;
}
.membership-content {
  padding: 24px 16px 12px 16px;
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  background-color: white !important;
}

.nav-tabs {
  margin-top: 12px;
  margin-bottom: 0;
}
.nav-item {
  cursor: pointer;
}
.nav-item a {
  height: 20px;
  display: flex;
  gap: 5px;
}
.nav-item i {
  font-size: 20px;
  color: #4CAF50;
}

.membership-signature {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.signature-controls {
  display: flex;
  align-items: flex-end;
}
.signature-controls .btn {
  margin-left: 5px;
  margin-bottom: 5px;
}
.signature-controls .file-selector {
  margin-bottom: 5px;
  flex-grow: 1;
}
.signature-image-container {
  position: relative;
}
.signature-image-container .btn-remove-signature {
  --color: #f44336;
  position: absolute;
  top: 0;
  right: 0;
  width: 28px;
  height: 28px;
  color: white;
  border-radius: 4px;
  border-color: var(--color);
  background-color: var(--color);
  font-size: 20px;
  padding: 0;
}
.signature-image-container .btn-remove-signature:hover {
  background-color: #bd362f;
}

.signature-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 180px;  /* to match height of details tab */
}

.membership-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
.membership-details-item {
  display: flex;
  margin-bottom: 10px;
}
.membership-details-item label {
  width: 150px;
}
.membership-details-item input:not([type=checkbox]), .membership-details-item select {
  flex-grow: 1;
  margin-bottom: 0;
}
.membership-details-item input[type=checkbox] {
  margin-bottom: 12px;
}

.remove-btn {
  margin:5px; 
}
.save-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-grow: 1;
}

.alert {
  /* override bootstrap styles */
  margin-bottom: 0;
  border-radius: 0;
}
.alert-small {
  /* override bootstrap styles */
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
}

</style>