<template>
  <div class="drawing-display">
    <div class="span10">
      <div class="widget-box">
        <div id="loading_progress">
          <div id="loading_progress_bar" 
            :aria-valuenow="loadingProgress" 
            aria-valuemin="0" 
            aria-valuemax="100"
            :style="{width: loadingProgress + '%'}">
          </div>
        </div>
        <drawing-layout class="drawing-layout"
          :drawingSets="drawingsData"
          :initialDrawing="initialSelection"
          :currentDrawing="drawingData"
          :annotations="filteredAnnotations"
          :currentSelectedAnnotation="selectedAnnotation"
          :readOnly="readOnly"
          :addingMode="addingMode"
          :newBalloonText="newBalloonText"
          :annotationId="nextAnnotationUUID"
          :findOrigin="findOrigin"
          :drawSettings="localDrawSettings"
          :colorSchemes="colorSchemes"
          @backgroundLoaded="val => layoutBackgroundImage = val"
          @update:chooseDrawing="val => applyDrawingChoice(val)"
          @update:currentAnnotationId="val => { selectedAnnotation = val; }"
          @update:annotations_data="val => updateAnnotationsData(val)"
          @update:newAnnotation="val => setNewAnnotation(val)"
          @updateSettings="val => { localDrawSettings = val; }"
          @saveSettings="val => saveDrawingSettings(val)"
          @bulk_moved_annotations="val => saveBulkArranged(val)"
          @get:assetUrl="({asset_id, callback}) => callback(getAssetUrl(asset_id))"
          @get:inspectionUrl="({annotation, callback}) => callback(getInspectionUrl(annotation))"
        />
      </div>
    </div>
    <div class="span2">
      <drawing-addnew v-if="inWorkPackage"
        :canAddNew="canAddNew"
        :solution="addSolution"
        :addMode="addingMode"
        :newPhotoField="newPhotoField"
        :saveInProgress="saveInProgress"
        :saveInspResult="saveInspResult"
        :currentSolutionKey="currentSolutionKey"
        :addInspections="currentSolutionDrafts"
        @update:modeChange="val => updateMode(val)"
        @update:newBalloonText="val => updateBalloonText(val)"
        @update:saveData="val => saveInspectionData(val)"
        @update:nextUUIDs="val => saveUUIDs(val)" />
      <drawing-settings-panel
        :showAnnotations="annotationOrder"
        :textBalloons="balloonSet"
        :currentSelectedAnnotation="selectedAnnotation"
        :addingMode="addingMode"
        :solutionsFieldList="solutionsFieldList"
        :solutionsFilter="solutionsFilter"
        :assignedToList="assignedToList"
        :drawingSettings="localDrawSettings"
        :colorSchemes="colorSchemes"
        :readOnly="readOnly"
        :allowSaveSettings="inWorkPackage && !readOnly"
        :saveSettingsResult="saveSettingsResult"
        @update:balloonSelected="val => { selectedAnnotation = val; }"
        @update:showSolutions="val => { solutionsFilter = val; }"
        @update:filter="newFilter => { annotationFilter = newFilter; }"
        @update:findOrigin="val => { findOrigin = val; }" 
        @updateSettings="val => { localDrawSettings = val; }"
        @saveSettings="val => saveDrawingSettings(val)"
        />
    </div>
  </div>

</template>

<script>
  import _ from 'lodash'
  import Vue from 'vue'

  import DrawingLayout from './drawing_sections/drawing_layout.vue'
  import DrawingSettingsPanel from './drawing_sections/drawing_settings_panel.vue'
  import DrawingAddnew from './drawing_sections/drawing_addnew.vue'
  import { OverlayGenerator } from "../lib/annotations/annotation_overlay_generator.js"
  import { AnnotationTypes } from "../lib/annotations/annotation"
  import { DefaultColorSchemes } from "../lib/color_scheme"

  var annotationType = AnnotationTypes();

  const PAGE_SIZE = 30;

  // To suport multiple solutions, need s drop down in Add New for each solution.
  // already in data
  export default {
    components: {
      DrawingLayout,
      DrawingSettingsPanel,
      DrawingAddnew
    },
    props: {
      drawingSets: Array,
      initialDrawing: String,
      addableSolutions: Array,
      solutionsFieldList: Object,
      assignedToList: Array,
      addInspections: Object,
      readOnly: Boolean,
      drawSettings: Object,
    },
    data: function () {
      let initialDrawingsData = this.removeEmptyDrawingSets();
      let initialSelection = _.isNil(this.initialDrawing) ? this.findFirstDrawing() : parseInt(this.initialDrawing);
      let initialFilter = _.keys(this.solutionsFieldList);
      let initialInspections = _.cloneDeep(this.addInspections);
      let allTypes = [annotationType.balloon, annotationType.arrow, annotationType.line, annotationType.circle, annotationType.square, annotationType.rectangle, annotationType.freeStyle];
      return {
        drawingsData: initialDrawingsData,
        initialSelection: initialSelection,
        selectedDrawing: null,
        annotationOrder: allTypes,
        selectedAnnotation: "",
        rememberedSelectedAnnotationForAdd: "",
        findOrigin: true,
        addingMode: false,
        newBalloonText: "",
        saveInProgress: false,
        saveAnnoResult: "",
        saveInspResult: "",
        newPhotoField: {},
        layoutBackgroundImage: null,
        fileLoadProgress: "",
        overlayCanvas: null,
        overlayContext: null,
        nextAnnotationAssetUUID: null,
        nextAnnotationUUID: null,
//        currentSolutionKey: "passive_fire_install",
        solutionsFilter: initialFilter,
        allDraftInspections: initialInspections,
        loadingProgress: 0,
        annotationFilter: function (annotations) {
          return annotations;
        },
        localDrawSettings: _.cloneDeep(this.drawSettings),
        colorSchemes: new DefaultColorSchemes(),
        saveSettingsResult: null,
        forceUpdateSelectedAnnotations: 0 // used to force the annotation filter to refresh itself when adding an annotation
      };
    },
    watch: {
    },
    computed: {
      currentSolutionKey: function () {
        // Only set if one solution filter selected and that is part of the addable solutions array
        let vm = this;
        return ((vm.solutionsFilter.length == 1) && (_.includes(vm.addableSolutions, vm.solutionsFilter[0]))) ? vm.solutionsFilter[0] : "";
      },
      canAddNew: function () {
        let vm = this;
        // we have a solutionlist and the top solution listed is one of the supported ones.
        return !vm.readOnly && !_.isEmpty(vm.topSolution) && (_.includes(vm.solutionsFilter, vm.currentSolutionKey)) && (!_.isNil(this.topSolution['drawing_field'])) && (!_.isEmpty(vm.topSolution['fields']))
      },
      currentSolutionDrafts: function () {
        return this.allDraftInspections[this.currentSolutionKey];
      },
      topSolution: function () {
        // top solution with fields
        let vm = this;
        //let result = _.find(vm.solutionsFieldList, function (solution, key) { return !_.isEmpty(solution['fields']) } ) ;
        let result = vm.solutionsFieldList[vm.currentSolutionKey] ;
        return _.isEmpty(vm.solutionsFieldList) ? {} : result;
      },
      solutionName: function () {
        return this.topSolution['name'];
      },
      solutionDrawingField: function () {
        return this.topSolution['drawing_field'];
      },
      addSolution: function () {
        return this.canAddNew ? _.cloneDeep(this.topSolution) : {}
      },
      drawingIndex: function () {
        return _.chain(this.drawingsData)
          .flatMap(function(drawing_set) {return drawing_set.drawings })
          .keyBy('id')
          .value();
      },
      drawingData: function () {
        return this.drawingIndex[this.selectedDrawing];
      },
      filteredAnnotations: function() {
        this.forceUpdateSelectedAnnotations; // force an update if this changes
        let annotations = this.drawingData == null ? [] : this.drawingData.annotations;
        let predicate = _.overEvery(this.annotationFilter);
        return _.filter(annotations, predicate);
      },
      balloonSet: function () {
        return _.chain(this.filteredAnnotations)
          .filter(function (annotation) { return (annotation.annotation_type == annotationType.balloon) })
          .orderBy('annotation_data.full_text')
          .value();
      },
      pathElements: function () {
        const organisationIdRegex = /^\/o\/(\d+)\/work_packages\/(\d+)\//g;
        return organisationIdRegex.exec(window.location.pathname)        
      },
      organisationId: function () {
        const regex = /(?<=^\/o\/)(\d+)(?=\/)/;
        let matches = window.location.pathname.match(regex);
        return matches?.[0];
      },
      workPackageId: function () {
        const regex = /(?<=\/work_packages\/)(\d+)(?=\/)/;
        let matches = window.location.pathname.match(regex);
        return matches?.[0];
      },
      inWorkPackage: function () {
        return !!this.workPackageId;
      }
    },
    mounted() {
      let initialSelection = _.isNil(this.initialDrawing) ? this.findFirstDrawing() : parseInt(this.initialDrawing);
      this.changeDrawing(initialSelection);
    },
    methods: {
      removeEmptyDrawingSets: function () {
        let drawing_sets = [];
        let index = 0;
        for (index = 0; index < this.drawingSets.length; index++) {
          if (this.drawingSets[index].drawings.length > 0) {
            drawing_sets.push(this.drawingSets[index]);
          }
        }
        return drawing_sets;

      },
      findFirstDrawing: function () {
        let index = 0;
        for (index = 0; index < this.drawingSets.length; index++) {
          if (this.drawingSets[index].drawings.length > 0) {
            return this.drawingSets[index].drawings[0].id;
          }
        }
        return null;
      },
      removeAnyDraft: function (inspection_id) {
        // If what was saved was a draft, remove it from the drafts
        let localDrafts = _.cloneDeep(this.currentSolutionDrafts);
        _.remove(localDrafts, function (insp) { return insp.id == inspection_id; });
        this.allDraftInspections[this.currentSolutionKey] = _.cloneDeep(localDrafts);
      },
      changeDrawing: function(drawing_id) {
        let drawing = this.drawingIndex[drawing_id];
        this.selectedDrawing = drawing_id;
        
        // fetch the annotations if we don't have them
        if (drawing.annotations == null) {
          this.getAnnotations(drawing_id).then(function (annotations) {
            // set the value this way so Vue can set up reactivity for this property
            Vue.set(drawing, 'annotations', annotations)
          }, function(annotations) {
            Vue.set(drawing, 'annotations', annotations)
          });
        }
      },
      getAnnotations: async function(drawing_id) {
        let countUrl = this.getAnnotationsCountUrl(this.drawingData.drawing_set_id, drawing_id);
        let countResponse = await this.$http.get(countUrl);
        let totalPages = Math.ceil(countResponse.body.count / PAGE_SIZE);
        let annotations = [];
        this.loadingProgress = 0;
        let progressInterval = 100 / totalPages;
        let page = 1;
        for (page = 1; page <= totalPages; page++) {
          let pageUrl = this.getAnnotationsUrl(this.drawingData.drawing_set_id, drawing_id, page, PAGE_SIZE);
          let response = await this.$http.get(pageUrl);
          annotations.push(...response.body);
          this.loadingProgress = Math.ceil(page * progressInterval);
        }
        return annotations;
      },
      updateAnnotationsData: function (data) {
        _.forEach(data, d => {
          let local = _.find(this.filteredAnnotations, a => a.uuid == d.uuid);
          if (local) {
            local.annotation_data = d.data;
            this.saveAnnotation(local);
            return;
          }
        })
      },
      saveAnnotation: function (annotation) {
        let vm = this;

        if (_.isNil(annotation)) {
          return
        }

        let postData = {
          asset_id: vm.drawingData.view_asset_id,
          annotation_type: annotation.annotation_type,
          annotation_data: annotation.annotation_data
        }
        vm.$http.post(vm.updateAnnotationUrl(vm.drawingData.drawing_set_id, vm.drawingData.id, annotation.id), postData).then(function (response) {
          this.saveAnnoResult = !!response.body.result ? "Success" : "Failed";
        }, function (response) {
          this.saveAnnoResult = "Failed";
        })
      },
      saveBulkArranged: function(val) {
        if (this.readOnly) return;
        let vm = this;
        let send_list = [];
        let updated_list = [];
        _.forEach(this.drawingData.annotations, (original) => {
          let moved = _.find(val, (a) => a.uuid == original.uuid && a.annotation_type == annotationType.balloon);
          if (moved) {
            let updated = _.cloneDeep(original);
            let endPoint = moved.annotation_data.end_point;
            updated.annotation_data.end_point.x = endPoint.x;
            updated.annotation_data.end_point.y = endPoint.y;
            updated_list.push(updated);
            send_list.push({uuid: moved.uuid, end_point: {x: endPoint.x, y: endPoint.y}});
          } else {
            updated_list.push(original);
          }
        });
        this.drawingData.annotations = updated_list;

        let postData = {
          asset_id: vm.drawingData.view_asset_id,
          moved: send_list
        }

        this.$http.put(this.updateAnnotationEndPointsUrl(this.drawingData.drawing_set_id, this.drawingData.id), postData);
      },
      saveInspectionData: function (inspectionData) {
        if (!this.inWorkPackage) return;
        let vm = this;

        let generator = OverlayGenerator(
          success, 
          message => vm.fileLoadProgress = message,
          message => vm.fileLoadProgress = message
        );

        generator.start(vm.newPhotoField, vm.layoutBackgroundImage, vm.nextAnnotationAssetUUID);

        function success() {
          vm.fileLoadProgress = "Completed";
          vm.postInspectionData(inspectionData);
        };
      },
      postInspectionData: function (inspectionData) {
        let vm = this;
        let drawingImageField = ""

        vm.saveInProgress = true;

        drawingImageField = vm.solutionDrawingField;
        
        inspectionData.inspection.data[drawingImageField] = vm.newPhotoField ;

        vm.$http.post(vm.updateInspectionUrl(inspectionData.uuid), inspectionData.inspection).then(function (response) {
          vm.saveInProgress = false;         
          vm.saveInspResult = !!response.body.result ? "Success" : "Failed";
          if (vm.saveInspResult == "Success") {
            _.forEach(vm.newPhotoField.annotations, function(annotation) {
              annotation.inspection_id = response.body.id;
              annotation.inspection_state = 'draft'
              annotation.solution_token = vm.currentSolutionKey;
              vm.drawingData.annotations.push(annotation);
              vm.forceUpdateSelectedAnnotations++;
              vm.removeAnyDraft(annotation.inspection_id);
            });
            let newId = vm.newPhotoField.annotations[0]?.uuid;
            if (newId) {
              vm.rememberedSelectedAnnotationForAdd = newId;
            }
          }
        }, function (response) {
          vm.saveInProgress = false;
          vm.saveInspResult = "Failed";
        });
        setTimeout( () => {
          vm.saveInspResult = "";
        },2000);        
      },
      saveDrawingSettings: function(settings) {
        if (this.readOnly) return;
        if (!this.inWorkPackage) return;
        let vm = this;
        vm.$http
          .put(vm.updateDrawingSettingsUrl(), {drawing_settings: settings})
          .then(function (response) {
            vm.saveSettingsResult = true;
          }, function (response) {
            vm.saveSettingsResult = false;
          })
      },
      saveUUIDs: function(newValue) {
        this.nextAnnotationUUID = newValue['annotationId'];
        this.nextAnnotationAssetUUID = newValue['annotationAssetId'];
      },
      setNewAnnotation: function(newValue) {
        let localField = _.isEmpty(newValue) ? {} : {
            type: "photo",
            window: {
              tl_x: 0, 
              tl_y: 0,
              scale_side: 1
            },
            asset_id: this.drawingData.view_asset_id,
            annotation_asset_id: this.nextAnnotationAssetUUID,
            drawing_id: this.drawingData.drawing_id,
            text_scale: 50, 
            annotations: [newValue]
          };

        this.newPhotoField = localField; 
        if (this.addingMode) {
          this.selectedAnnotation = newValue?.uuid;
        }
      },
      updateBalloonText: function (newValue) {
        this.newBalloonText = newValue;
      },
      updateMode: function (settings_data) {
        this.addingMode = settings_data['addingMode'];
        if (this.addingMode) {
          this.rememberedSelectedAnnotationForAdd = this.selectedAnnotation;
          this.selectedAnnotation = "";
        } else {
          this.selectedAnnotation = this.rememberedSelectedAnnotationForAdd;
        }
      },
      applyDrawingChoice: function (settings_data) {
        this.changeDrawing(settings_data['selectedDrawing']);
      },
      getAnnotationsCountUrl: function(drawing_set_id, drawing_id) {
        let countBase = this.drawingAnnotationsBasePath(drawing_set_id, drawing_id) + '/count';
        let filter = this.workPackageUrlFilter();
        return filter ? countBase + '?' + filter : countBase;
      },
      getAnnotationsUrl: function(drawing_set_id, drawing_id, page, size) {
        let annotationsBase = this.drawingAnnotationsBasePath(drawing_set_id, drawing_id) + '/annotations_for_drawing?';
        let filter = this.workPackageUrlFilter();
        let query = 'page=' + page + '&page_size=' + size;
        return annotationsBase + (filter ? filter + '&' : '') + query;
      },
      updateAnnotationUrl: function (drawing_set_id, drawing_id, annotation_id) {
        return this.drawingAnnotationsBasePath(drawing_set_id, drawing_id) + '/' + annotation_id + '/update_from_drawing'
      },
      updateAnnotationEndPointsUrl: function (drawing_set_id, drawing_id) {
        return this.drawingAnnotationsBasePath(drawing_set_id, drawing_id) + '/update_end_points'
      },
      getAssetUrl: function (assetId) {
        return '/o/' + this.organisationId + '/assets/' + assetId;
      },
      getInspectionUrl: function (annotation) {
        let inspection_id = annotation?.inspection_id ?? "";
        if (!inspection_id) return "";
        let linked_feature = annotation?.linked_feature ?? "";
        let fragment = _.isEmpty(linked_feature) ? "" :  "#" + this.featureUUIDFromPath(linked_feature);
        return "/o/" + this.organisationId + "/inspections/" + inspection_id + fragment;
      },
      updateInspectionUrl: function (inspection_uuid) {
        return this.pathElements[0] + "inspections/" + inspection_uuid + "/update_from_drawing"
      },
      updateDrawingSettingsUrl: function () {
        return this.pathElements[0] + "drawing_settings";
      },
      drawingAnnotationsBasePath: function(drawing_set_id, drawing_id) {
        return '/o/' + this.organisationId + '/drawing_sets/' + drawing_set_id + '/drawings/' + drawing_id + '/drawing_annotations';
      },
      workPackageUrlFilter: function () {
        let workPackageId = this.workPackageId;
        return workPackageId ? 'work_package_id=' + workPackageId : '';
      },
      featureUUIDFromPath(path) {
        let localPathSections = path.split('/');
        if (localPathSections.length > 1) {
          // trim to last piece
          localPathSections = localPathSections[localPathSections.length - 1].split(":");
        } else {
          localPathSections = localPathSections[0].split(":");
        }
        return localPathSections[1];
      },
    }
  }
</script>

<style scoped lang="scss">
.drawing-layout {
  margin-top: 6px;
}
#drawingView {
    border: 3px solid black;
}

#loading_progress {
  width: 100%;
  background-color: #ddd;
}

#loading_progress_bar {
  width: 0%;
  height: 2px;
  background-color: #0099FF;
}
</style>
