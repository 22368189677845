<template>
  <div class="inspection-section">
    <component
      :is="componentFor(field)"
      :specification="field"
      :solution-type="solutionType"
      :data="data[field.reference]"
      :notifyFieldData="notifyFieldData[field.reference]"
      @registerFieldwatchers="val => registerFieldwatchers(val)"
      @registerLinks="val => registerLinks(val)"
      @update:data="val => fieldUpdate(field, val)"
      @clear:data="() => fieldRemoval(field)"
      :read-only="readOnly"
      :isRow=false
      :isNewForm="newForm"
      :drawing-sets="drawingSets"
      :under-rollup="getRollup(field.reference)"
      :group-visible="getGroupVisible(field.reference)"
      :group-state="getGroupState(field.reference)"
      :control-event="controlEventName(field.reference)"
      :control-state="getControlState(field.reference)"
      :show-validation="showValidation"
      :validate-now="validateNow"
      :prefill-data="prefillData[field.reference]"
      :parentReference="instanceReference"
      @update:errors="newErrors => updateValidityState(newErrors)"
      @notifyFieldEvent="val => onNotifyFieldEvent(val)"
      v-for="field in fields" :key="field.reference">
    </component>
  </div>
</template>

<script>
  import _ from 'lodash'
  import labelTemplateMixin from '../mixins/label_template_mixin'
  import DateField from './fields/date.vue'
  import DecimalField from './fields/decimal.vue'
  import FeatureField from './fields/feature.vue'
  import GuidanceField from './fields/guidance.vue'
  import IntegerField from './fields/integer.vue'
  import LocationField from './fields/location.vue'
  import SelectionField from './fields/selection.vue'
  import TextField from './fields/text.vue'
  import TimeField from './fields/time.vue'
  import PhotoField from './fields/photo.vue'

  export default {
    components: {
      DateField,
      DecimalField,
      FeatureField,
      GuidanceField,
      IntegerField,
      LocationField,
      SelectionField,
      TextField,
      TimeField,
      PhotoField
    },
    mixins: [labelTemplateMixin],
    inject: ['sourceLists'],
    props: {
      fields: Array,
      solutionType: String,
      data: Object,
      readOnly: Boolean,
      newForm: Boolean,
      drawingSets: Array,
      underRollups: Object,
      defaultRollup: Object,
      controlledFields: Array,
      showValidation: Boolean,
      validateNow: String,
      instanceReference: String,
      subFeatureToShow: String,
      notifyFieldEvent: Object,
    },
    data: function () {
      let initialStates = this.evaluateRules();
      let initialPrefillDataList = this.prefillDataList(this.data);
      let initialFieldsToWatch = this.getFieldsToWatch();
      let initialNotify = _.reduce(this.fields, (o, f) => {
        o[f.reference] = {};
        return o;
        }, {});
      return {
        dataChanged: false,
        controlledFieldStates: initialStates,
        fieldValidity: [],
        currentValidity: 'valid',
        fieldsToWatch: initialFieldsToWatch,
        prefillData: initialPrefillDataList,
        registeredLinks: [],
        watchedFields: {},
        notifyFieldData: initialNotify, 
      }
    },
    watch: {
      // subFeatureToShow: function (newValue) {
      //   if (_.isEmpty(newValue) && this.showChildRow) {
      //     // remove row
      //   } else {
      //     // Add row
      //   }
      // },
      notifyFieldEvent: function(val) {
        this.onNotifyFieldEvent(val);
      },
    },
    methods: {
      registerFieldwatchers: function(reg) {
        let vm = this;
        _.forEach(reg.fields ?? [], field => {
          let w = [...vm.watchedFields[field] ?? [], reg.watcher];
          let watchers = _.uniq(w);
          vm.watchedFields[field] = watchers;
        });
      },
      registerLinks: function(reg) {
        let newReg = _.map(reg.links, (l) => { return {ownerReference: reg.owner, targetReference: l}; })
        this.registeredLinks = this.registeredLinks.concat(newReg);
      },
      onFieldChanged(updatedFieldReference, data) {
        let watchers = this.watchedFields[updatedFieldReference];
        if (watchers) {
          let ev = {
            type: "watchedFieldChanged",
            fields: data,
          };
          _.forEach(watchers, (watcher) => {
            this.notifyFieldData[watcher] = ev;
          });
        }
      },
      onNotifyFieldEvent: function(val) {
        if (!val.destination) {
          _.forEach(this.registeredLinks, (l) => {
            if (l.targetReference == val.source) {
              val.destination = l.ownerReference;
              this.notifyFieldData[l.ownerReference] = val;
            }
          });
        }
        if (!!val.destination) {
          // we work with relative paths only
          // we're making the assumption that there are no deep paths
          // TODO: adapt to support nested features
          // TODO: adapt to support parent paths
          // TODO: adapt to support absolute paths (eg if path starts with a '/')
          let parts = val.destination.split(':');
          if (parts.length == 2) {
            val.destination = parts[1];
            this.notifyFieldData[parts[0]] = val;
          } else {
            this.notifyFieldData[val.destination] = val;
          }
        }
      },
      fieldsByReference: function () {
        return _.keyBy(this.fields, 'reference');
      },
      getFieldsToWatch: function() {
        let vm = this;
        // check all fields and return references of those that need prefills
        let result = [];
        _.forEach(vm.fields, function (thisField) {
          if (!_.isEmpty(thisField['prefill'])) { result.push(thisField['prefill'][0]['form']); }
        })
        return result;
      },
      listOfPrefills: function () {
        let vm = this;
        // check all fields and return references of those that need prefills
        let result = {};
        let prefill = {};
        _.forEach(vm.fields, function (thisField) {
          if (!_.isEmpty(thisField['prefill'])) {
            // There is a prefill request
            prefill = thisField['prefill'][0];
            result[thisField.reference] = { "source": prefill.form, "fields": prefill.fields };
          }
        })
        return result;
      },
      featuresListLabel: function(fieldReference) {
        return this.fieldsByReference()[fieldReference]['list_label']
      },
      prefillDataList: function(dataSource) {
        // Go through the preFillRequests and generate the lists for them
        // Each list is added to the prefillData object
        let result = {};
        let vm = this; 
        let returnedList = [];
        _.forEach(vm.listOfPrefills(), function (thisPrefillRequest, thisTargetField) {
          returnedList = vm.featureDataList(dataSource, thisPrefillRequest.source, thisPrefillRequest.fields);
          if (!_.isEmpty(returnedList)) {
            result[thisTargetField] = returnedList;
          }
        });
        return result
      },
      featureDataList: function(dataSource, fieldReference, fieldsList) {
        // For a single request, returns the list with the data
        let result = {};
        let vm = this; 
        if (vm.canUseFieldSource(dataSource, fieldReference)) {
          let level1 = vm.featuresListLabel(fieldReference)[0];
          let level1Processed = '';
          let level2Processed = '';

          let testSubst = new RegExp(/%{([\s\S]+?)}/,'g');
          if (!testSubst.test(level1)) {
            // is a static string
            result[level1] = [];
          } else {
            // is a subst string - add each level
            _.forEach(dataSource[fieldReference]['values'], function(instance){
              level1Processed = vm.interpolatedText(level1, instance.data);
              if (!_.isEmpty(_.trim(level1Processed))) {
                result[level1Processed] = [];
              }
            })
          }
          let level2 = vm.featuresListLabel(fieldReference)[1];
          _.forEach(dataSource[fieldReference]['values'], function(instance){
            level1Processed = vm.interpolatedText(level1, instance.data);
            level2Processed = vm.interpolatedText(level2, instance.data);
            if (!_.isEmpty(_.trim(level1Processed)) && !_.isEmpty(_.trim(level2Processed))) {
              result[level1Processed].push(
                { 
                  "value": level2Processed,
                  "data": vm.pullFieldsFromData(instance.data, fieldsList)
                }
              );
            }
          })
        }
        return result
      },
      canUseFieldSource: function (dataSource, fieldReference) {
        let vm = this;
        return !_.isEmpty(vm.featuresListLabel(fieldReference)) 
            && !_.isEmpty(dataSource)
            && !_.isEmpty(dataSource[fieldReference]) 
            && !_.isEmpty(dataSource[fieldReference]['values']) 
            && !_.isEmpty(dataSource[fieldReference]['values'][0]['data']);
      },
      pullFieldsFromData: function (fieldData, fieldsList) {
        // for the data given, only pull out those items from the FieldsList
        let vm = this; 
        let result = {}; 
        _.forEach(fieldsList, function (fieldName) {
          if (!_.isUndefined(fieldData[fieldName])) {
            let source = fieldData[fieldName];
            switch (fieldData[fieldName].type) {
              case "selection":
                result[fieldName] = {};
                result[fieldName]['type'] = 'text';
                result[fieldName]['value'] = source.selections.map(_.property('value')).join(', ');
                break;
              case "integer":
              case "decimal":
                result[fieldName] = {};
                result[fieldName]['type'] = 'text';
                result[fieldName]['value'] = _.toString(source['value'])
                break;
              case "date":
                result[fieldName] = {};
                result[fieldName]['type'] = 'text';
                result[fieldName]['value'] = _.isNil(source['year']) ? "" : _.join([_.padStart(source.day, 2, '0'), _.padStart(source.month, 2, '0'), source.year],"-");
                break;
              default: 
                result[fieldName] = _.cloneDeep(source);
                break;
            }
          }
        })
        return result;
      },
      updateValidityState: function (fieldError) {
        let fieldName = _.keys(fieldError)[0];
        let fieldValue = fieldError[fieldName];
        let fieldIndex = -1;
        let originalValidity = this.currentValidity;
        let vm = this;
        if (fieldValue == 'valid' && _.includes(vm.fieldValidity, fieldName)) {
          // field was in array, remove it
          fieldIndex = _.findIndex(vm.fieldValidity, function (entry) {
            return entry == fieldName;
          });
          if (fieldIndex > -1) {
            // found it - remove it
            vm.fieldValidity.splice(fieldIndex, 1);
          }
        } ;
        if (fieldValue == 'invalid' && !_.includes(vm.fieldValidity, fieldName)) {
          // add it in
          vm.fieldValidity.push(fieldName);
        } ;

        //if (originalValidity != vm.setCurrentValidity()) {
          let dataToSend = {};
          dataToSend[vm.instanceReference] = vm.setCurrentValidity(); //vm.currentValidity;
          this.$emit('update:errors', dataToSend);
        //}
      },
      setCurrentValidity: function () {
        this.currentValidity = (this.fieldValidity.length == 0) ? 'valid' : 'invalid';
        return this.currentValidity;
      },
      getControlState: function (fieldRef) {
        return this.controlledFieldStates[fieldRef]
      },
      controlEventName: function (fieldRef) {
        let vm = this;
        let result = '';
        if (_.includes(vm.controlledFields, fieldRef)) {
          result = (vm.instanceReference + '-' + 'showHideResults');
        }
        return result;
      },
      getRollup: function (fieldRef) {
        return !!this.underRollups[fieldRef] ? this.underRollups[fieldRef] : '';
      },
      getGroupVisible: function(fieldRef) {
        let thisVisible = true;
        let vm = this;
        if (!!this.underRollups[fieldRef]) {
          // check the feild is not the same as the rollup - can't control self!
          if (fieldRef != _.last(_.split(vm.getRollup(fieldRef),'-'))) {
            thisVisible = !!!this.defaultRollup[this.getRollup(fieldRef)];          
          }
        } 
        return thisVisible;
      },
      getGroupState: function(fieldRef) {
        let thisState = '';
        let vm = this;
        if (!!this.underRollups[fieldRef]) {
          // check the feild is not the same as the rollup - can't control self!
          let groupField = _.last(_.split(vm.getRollup(fieldRef),'-'))
          if (fieldRef != groupField) {
            thisState = vm.getControlState(groupField)
            if (!thisState) {
              // group not found in controlled fields list -> use the schema default
              let groupFieldSchema = vm.fields.find(f => f.reference == groupField);
              if (groupFieldSchema) {
                thisState = groupFieldSchema.hidden ? "hide" : "show";
              }
            }
          }
        } 
        return thisState;
      },
      componentFor: function (field) {
        return field.type + "-field";
      },
      fieldUpdate: function (field, value) {
        let reference = field.reference
        let data = _.cloneDeep(this.data)
        data[reference] = value

        if (_.includes(this.fieldsToWatch, reference)) {
          this.prefillData = this.prefillDataList(data);
        }
        this.$emit('update:data', data)
        this.onFieldChanged(reference, data)
      },
      fieldRemoval: function (field) {
        let reference = field.reference
        let data = _.cloneDeep(this.data)
        delete data[reference]

        this.$emit('update:data', data)
        this.onFieldChanged(reference, data)
      },
      hasDefault: function (field) {
        return !_.isEmpty(field.default_selections)
            || ((field.type === 'date' || field.type === 'time') && (!_.has(field, 'set_on_create') || field.set_on_create));
      },
      isMulti: function (field) {
        return field.multiple;
      },
      originalOptions: function (field) {
        return this.fullOptionsToUse(field);
        // return _.map(field.options, function (option) {
        //   return {key: option[0], value: option[1]}
        // })
      },
      fullOptionsToUse: function (field) {
        // Options, lists and any selected key value pairs
        let vm = this;
        let fullOptions = [];
        let selectedData = vm.data[field.reference];
        let keyedData = _.map(selectedData, _.property('key'));
        if (!!field['options'] && !_.isEmpty(field['options'])) {
          // we have options - use them
          fullOptions = _.map(field.options, function (option) {
            return {key: option[0], value: option[1]}
          });
        } else if (!!field['source'] && !_.isEmpty(vm.sourceLists[field['source']])) {
          let optionsToUse = _.cloneDeep(vm.sourceLists[field['source']].items);

          fullOptions = _.map(_.filter(optionsToUse, function (listItem) { 
            return _.includes(keyedData, listItem.key) ? true : listItem.use; 
          }), function(listItem) { return {key: listItem.key, value: listItem.value } });
        }
        // Check actual data is in the full options (whether in list or not)
        keyedData = _.map(fullOptions, _.property('key'));
        _.forEach(selectedData, function(dataItem) { if (!_.includes(keyedData, dataItem.key)) {
            fullOptions.unshift(dataItem);
          }
        });
        return fullOptions;
      },
      setDefaults: function(field) {
        if (this.hasDefault(field)) {
          this.dataChanged = true;
          let reference = field.reference
          let existing = this.data[reference];
          if (!existing) {
            let now = new Date();
            switch(field.type) {
              case 'date':
                this.data[reference] = {
                  type: 'date',
                  year: now.getFullYear(),
                  month: now.getMonth() + 1,
                  day: now.getDate()
                };
                break;
              case 'time':
                this.data[reference] = {
                  type: 'time',
                  hour: now.getHours(),
                  minute: now.getMinutes(),
                  second: 0
                };
                break;
              case 'selection':
                this.data[reference] = { 
                  type: 'selection',
                  selections: this.getSelection(field)
                };
            }
          }
        }
      },
      getSelection: function (field) {
        // if new and no data - add defaults
        let newSelections = this.isMulti(field) ? field.default_selections : field.default_selections[0];
        let selectedKeys = _.castArray(newSelections);
        let selectedOptions = _.filter(this.originalOptions(field), function (o) {
          return _.includes(selectedKeys, o.key);
        });
        return selectedOptions;
      },
      evaluateRule: function (thisRule, selections) {
        let test = _.toUpper(thisRule.test);
        let searchVals = thisRule.keys;
        let fields = thisRule['field-references'];
        let emptySelection = (_.isEmpty(selections)) || (_.filter(selections, _.isEmpty).length >= 1);
        let matched = false;
        if (test == 'OR') {
          if (_.isEmpty(searchVals) && emptySelection) {
            matched = true;
          } else {
            _.forEach(searchVals, function(thisValue) {
              if (thisValue == "") {
                if (emptySelection) {
                  matched = true
                }
              } else { 
                if (_.includes(selections, thisValue)) {
                  // If any matched
                  matched = true
                }
              }
            })            
          }

        } 
        if (test == 'AND') {
          // Treat as AND - all have to match
          let misMatch = ''; // first time
          _.forEach(searchVals, function(thisValue) {
            if (!_.includes(selections, thisValue)) {
              misMatch = thisValue;
            }
          })
          matched = !!!misMatch;
        }
        return matched;
      },
      evaluateRules: function () {
        // go through each rule, evaluate it. 
        // IF a rule matches addfields with behaviour to match list, 
        // else add fields to mistmath list
        let matched = {};
        let mismatched = {};
        let vm = this;
        _.forEach(this.fields, function(thisField) {
          if (thisField.type == 'selection') {
            // check for rules
            if (!!thisField['field-control']) {
              // we have fields to control
              let rules = thisField['field-control'];
              _.forEach(rules, function(thisRule){
                let behaviour = _.toLower(thisRule.behaviour);
                let selections = !vm.data[thisField.reference] ? [] : vm.data[thisField.reference]['selections'];
                if ((vm.newForm && !vm.readOnly) && _.isEmpty(selections) && vm.hasDefault(thisField)) { selections = vm.getSelection(thisField) };
                let selectionKeys = [];
                _.forEach(selections, entry => selectionKeys.push(entry.key));
                if (vm.evaluateRule(thisRule, selectionKeys)) {
                  // add matched fields
                  _.forEach(thisRule['field-references'], function(thisFieldName) {
                    if (!!!matched[thisFieldName]) {
                      // if fieldname alrady matched, do not alter entry
                      matched[thisFieldName] = behaviour;
                    }
                  });
                } else {
                  _.forEach(thisRule['field-references'], function(thisFieldName) {
                    mismatched[thisFieldName] = (behaviour == 'show' ? 'hide' : 'show');
                  });
                };
              });
              // merge data into one Hash
              _.forEach(mismatched, function(entry, key) { if (!!!matched[key]) {matched[key] = entry}})
            }
          }
        })
        return matched;
      }
    },
    created: function() {
      // iterate thorugh data and add defaults if there are any
      if (this.newForm && !this.readOnly) {
        _.forEach(this.fields, field => this.setDefaults(field));
        if (this.dataChanged) { 
          let data = _.cloneDeep(this.data);
          this.$emit('update:data', data) ;
        };
      }
    }
  }
</script>

<style scoped lang="scss">
</style>