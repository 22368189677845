<template>
  <div class="common-data-form">

    <div class="row-fluid">
      <div class="span12">
        <div class="widget-box span9">
          <div class="widget-title">
            <h5 title="Set values to prefill fields on the forms">Common Inspection Data</h5>
            <span title="Tick the box to show all fields available on all forms in this Work Package" class="show-item-list" v-show="!disabled && !noMainFields">
            <input type="checkbox" id="check_show_unused" class="choice-checkbox" v-model="showUnused"/>
            <label for="check_show_unused">Show form fields that can be used</label>
            </span>
          </div>
          <div class="widget-content common-heading" v-show="!disabled">
            <h6>Common Inspection Data. Set values to prefill fields on the forms. <span class="heading-guidance">Tick the box on the right hand side to use a field.</span> </h6>
          </div>
          <div class="widget-content" v-if="noMainFields & !commonDataInUse">
            Common data can be added once Inspection Types with fields on the main form are added and the Work Package saved.
          </div>
          <div class="widget-content" v-else-if="!commonDataInUse & disabled">
            Common data not currently in use.
          </div>
          <div class="widget-content" v-else>
            <div id="data-field" ref="dataField">
              <draggable 
                class="list-group"
                tag="ul"
                v-model="options"
                v-bind="dragOptions"
                handle=".handle"
                >
                <li class="list-group-item" v-bind:class="{'hidden': isHidden(element), unused: !element.use }" v-for="(element, idx) in options" :key="element.reference">
                  <div>
                    <div class="span3">
                      <i class="material-icons annotation-unstyled handle">dehaze</i>
                      <span class="text label-text">{{ element.label }}</span>
                    </div>
                    <div class="span8 value-container">
                      <input class="form-control value-text" type="text" v-model="element.value" style="width: 400px" v-on:change="emitList" :disabled="disabled"/>
                    </div>
                    <div class="span1">
                      <span class="material-icons annotation-unstyled trash-entry" v-show="isDeletable(element) && !disabled" title="Delete Work Package Note" @click="removeAt(idx)">delete_outline</span>                    
                      <input type="checkbox" class="form-control" v-show="!isDeletable(element)" v-model="element.use" v-on:change="emitList" :title="useListCaption(element.use)" :disabled="disabled"/>                  
                    </div>
                  </div>
                </li>
              </draggable>
            </div>
            <div class="widget-content common-heading" v-show="!disabled">
              <h6>Add Work Package Note for App Users</h6>
            </div>
            <div class="btn-group list-group-item" v-show="!disabled">
              <div class="span5">
                <label for="add_new_value">This will appear in the App at the top of the Work Package. </label>
                <input id="add_new_value" type="text" class="form-control-left" v-on:keydown.enter.prevent="" v-model="newValue"/>
              </div>
              <div class="span2">
                <button class="btn btn-secondary btn-add" @click.prevent="addFieldObject(); return false" :disabled="!allowAdd" title="Work Package notes appear in the App at the top of the Work Package.">Add Note</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import draggable from 'vuedraggable'
  import _ from 'lodash'
  export default {
      components: {
        draggable
      },
      props: {
      commonData: {
        type: Array,
        default: function () { return []; }
      },
      mainFields: {
        type: Object,
        default: function () { return {}; }
      },
      readOnly: Boolean,
      disabled: Boolean
    },
    data: function () {
      let initialCommonData = this.createCommonData(_.cloneDeep(this.mainFields), _.cloneDeep(this.commonData));
      return {
        showUnused: !this.readOnly,
        drag: false,
        newLabel: "",
        newValue: "",
        workingCommonData: initialCommonData
      }
    },
    computed: {
      options: {
        get() {
          return this.workingCommonData;
        },
        set(value) {
          this.workingCommonData = value;
          this.emitList();
        }
      },
      dragOptions: function() {
        return {
          animation: 200,
          group: "description",
          disabled: this.disabled,
          ghostClass: "ghost"
        };
      },
      noMainFields: function () {
        return _.isEmpty(this.mainFields);
      },
      fieldsInUse: function () {
        let vm = this;
        return _.filter(this.workingCommonData, function (fieldObject) { return !!fieldObject['use'] })
      },
      commonDataInUse: function () {
        return !_.isEmpty(this.fieldsInUse);
      },
      allowAdd: function() {
        // return !_.isEmpty(this.newLabel.trim()) && !_.isEmpty(this.newValue.trim());
        return !_.isEmpty(this.newValue.trim());
      }
    },
    methods: {
      isDeletable: function (fieldObject) {
        return !!fieldObject['deletable'];
      },
      useListCaption: function (useListItem) {
        return useListItem ? "Untick to hide this value." : "Tick to use this value.";
      },
      isHidden: function (element) {
        return !(this.showUnused || element.use);
      },
      createCommonData: function (fieldsAvailable, initialCommonData) {
        let localWorkingCommonData = [];
        let fieldKeys = _.keys(fieldsAvailable);
        let vm = this;
        _.forEach(initialCommonData, function (fieldObject) {
          let fieldRef = fieldObject['reference'];
          if (fieldKeys.includes(fieldRef)) { 
            fieldObject['deletable'] = false;
            fieldsAvailable[fieldRef]['in_use'] = true;
          } else {
            fieldObject['deletable'] = true;
          };
          vm.addCommonField(localWorkingCommonData, fieldObject);
        });
        _.forEach(fieldKeys, function(fieldRef) {
          let copyObject = _.cloneDeep(fieldsAvailable[fieldRef])
          vm.addAvailableField(localWorkingCommonData,copyObject)
        })
        return localWorkingCommonData;
      },
      addCommonField: function (workingCommonData, fieldObject) {
        let copyObject = _.cloneDeep(fieldObject);
        this.useField(copyObject);
        workingCommonData.push(copyObject);
      },
      addAvailableField: function (workingCommonData, fieldObject) {
        if (!!!fieldObject['in_use']) {
          let copyObject = _.pick(fieldObject,['label','reference']);
          copyObject['value'] = "";
          copyObject['deletable'] = false;
          this.unUseField(copyObject);
          workingCommonData.push(copyObject)
        }
      },
      useField: function (fieldObject) {
        this.setFieldState(fieldObject, true);
      },
      unUseField: function (fieldObject) {
        this.setFieldState(fieldObject, false);
      },
      setFieldState: function (fieldObject, state) {
        fieldObject['use'] = !!state;
      },
      unUsedFields: function (fieldsAvailable, fieldsInUse) {
        let unUsed = [];
        let inUse = _.map(fieldsInUse, "reference");
        return _.filter(fieldsAvailable, function(value, key) { return !inUse.includes(key) });
      },
      emitList: function () {
        let vm = this;
        this.$emit('update:common-data', vm.activeCommonData());
      },
      activeCommonData: function () {
        let vm = this;
        return _.map(_.filter(vm.workingCommonData, {'use': true}), function(fieldObject) { return _.pick(fieldObject,['label','reference','value'])})
      },
      removeAt(idx) {
        this.workingCommonData.splice(idx, 1);
        this.emitList()
      },      
      nextReference: function () {
        let vm = this;
        return (_.max(_.map(vm.workingCommonData, function (fieldObject) { return _.isNaN(parseInt(fieldObject.reference)) ? 0 : parseInt(fieldObject.reference) })) + 1).toString() ;
      },
      useItem: function (fieldObject) {
       this.workingCommonData.push(fieldObject);
       this.emitList()
      },
      addFieldObject: function () {
        // use the text and generate a list item
        let vm = this;
        let fieldObject = { reference: vm.nextReference(), label: "Work Package Note", value: vm.newValue, deletable: true, use: true } ;
        vm.useItem(fieldObject) ;
        vm.newLabel = "";
        vm.newValue = "";
        setTimeout( () => {
          vm.scrollItemList();
        },100);
      },
      scrollItemList: function () {
        let container = this.$refs.dataField;
        if (!_.isNil(container)) {
          container.scrollTop = container.scrollHeight;
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .currentlist {
    background-color:#FFFF00 !important;
  }
  #data-field {
    border: #cdcdcd 1px solid;
    min-height: 200px;
    max-height: 300px;
    overflow-y: scroll;
  }
  .list-text { 
    padding-left: 4px;
    cursor: pointer;
  }
  .flip-list-move {
    transition: transform 0.5s;
  }
  .no-move {
    transition: transform 0s;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  .list-group {
    min-height: 20px;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .list-group-item {
    cursor: pointer;
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #eee;
    border: 1px solid rgba(0,0,0,.125);
    overflow: auto;

    &.unused {
      opacity: 0.5;
    }

    &.hidden {
      display: none;
    }
  }
  .list-group-item i {
    cursor: move;
  }
  .handle {
    float: left;
    padding-top: 4px;
    padding-bottom: 8px;
    margin-right: 8px
  }
  .trash-entry {
    margin-top: 0px;
    font-size: 20px;
    float: right;
  }
  .form-control {
    margin-top: 0px;
    float: right;
  }
  .form-control-left {
    margin-top: 0px;
    float: left;
    width: 100%;
  }
  .show-item-list {
    list-style-type: none;
    margin-left: 0px;
    margin-bottom: 0px;
    margin-top: 7px;
    margin-right: 5px;
    float: right;
  }
  .show-item-list label {
    display: inline-block;
  }
  .choice-checkbox {
    margin-top: 0px;
    margin-bottom: 2px;
  }
  .item-buttons {
    text-align: right;
    margin-top: 6px;
    margin-right: 12px;
    margin-left: 12px;
  }
  .value-text {
    resize: vertical;
    min-height: 22px;
  }
  .label-text {
    width: 200px;
  }
  .value-container {
    text-align: end;
    display: flex;
  }
  .btn-add {
    float: right;
    margin-top: 22px;
  }
  .common-heading {
    padding-bottom: 0px;
  }
  .heading-guidance {
    font-style: italic; 
  }
</style>